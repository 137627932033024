/* tslint:disable:no-default-export */

import React from 'react';

import { TokenDisplay } from '../../../components';
import LayoutDefault from '../../../components/capital/Layouts/LayoutDefault';

export default () => (
  <LayoutDefault
    activeTab="styles"
    title="Token Library"
    description="All tokens supplied by Capital Design System."
    wide={true}
  >
    <TokenDisplay />
  </LayoutDefault>
);
